<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pl1 pr4">
            <MetaRightSideHeader>
              <template #title>视图管理</template>
              <template #bottom-tip>
                视图可以直观表现出合同的相关数据变化。
              </template>
              <template #middle-right><el-button type="primary" @click="showCreateVisualizationDialog=true">添加视图</el-button></template>
            </MetaRightSideHeader>
            <div class="data-container shadow" style="flex: 1">
              <MetaListTable
                  :table-data="visualizations"
                  :table-height="tableHeight"

              >
                <el-table-column prop="param.configuration.data.title" label="标题">
                </el-table-column>
                <el-table-column align="right">
                  <template #default="scope">
                    <el-button type="primary" size="small" @click="gotoEdit(scope.row)">编辑</el-button>
                    <el-button type="danger" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </MetaListTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="showCreateVisualizationDialog" title="请选择视图类型">
    <div class="container" @click="createVisualization('PIE')">
      <div class="icon">
        <i class="el-icon-pie-chart"></i>
        <span>饼图</span>
      </div>

      <div class="icon" @click="createVisualization('LINE')">
        <i class="el-icon-data-line"></i>
        <span>折线图</span>
      </div>

      <div class="icon" @click="createVisualization('BAR')">
        <i class="el-icon-data-analysis"></i>
        <span>柱状图</span>
      </div>

      <div class="icon" @click="createVisualization('TABLE')">
        <i class="el-icon-tickets"></i>
        <span>列表</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {getTeamVisualization} from "../api/api";
import MetaListTable from "../components/list/MetaListTable";
import {CHART_TYPES} from "../components/charts/js/statisticChartConfig"
export default {
  name: "VisualizationManagement",
  components: {MetaListTable, MetaRightSideHeader, ContractSettingLeftMenu},
  data(){
    return{
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      visualizations:[],
      tableHeight: document.body.clientHeight - 150,
      showCreateVisualizationDialog:false
    }
  },
  mounted() {
    getTeamVisualization(this.userInfo.mainSiteTeamId).then(res=>{
      if(res.data.code===0) {
        this.visualizations =res.data.data;
        this.visualizations.forEach((item)=>{
          this.loadChartData(item.param);
        })
      }
    })
  },
  methods:{
    gotoEdit(visualization){
      this.$router.push({
        path:'/statistic',
        query:{
          id:visualization.id,
          statisticType:CHART_TYPES[visualization.param.chartType],
          buckets:JSON.stringify(visualization.param.configuration.data.buckets),
          metrics:JSON.stringify(visualization.param.configuration.data.metrics),
          timeRange:JSON.stringify(visualization.param.timeRange),
          filters:JSON.stringify(visualization.param.filters),
          title:visualization.param.configuration.data.title,
          desc:visualization.param.configuration.data.desc
        }
      },)
    },
    createVisualization(chartType){
      this.$router.push({
        path:'/statistic',
        query:{
          statisticType:chartType,
        }
      },)
    }
  }
}
</script>

<style scoped>
@import url("../assets/css/concord.css");

.container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.container .icon{
  margin: 10px;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 20px;
}

.container .icon:hover{
  background: rgba(11, 125, 218, 0.1);
  cursor: pointer;
}

</style>
